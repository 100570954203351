import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleImg,
	ArticleList,
	ArticleListItem,
	ArticlePage, ArticleQuote
} from '../components/common/Article'
import { graphql } from 'gatsby'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function TimeManagementStrategies(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "5 Effective Time Management Strategies to Boost Productivity";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"5 Effective Time Management Strategies to Boost Productivity - " + productName}
				description="Reach your goals effectively using time management strategies."
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Time Management Strategies"
					caption={<UnsplashPhotoCredit author="O12"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>
					<ArticleCopy>
						Does the looming challenge of managing your time effectively give you pause?
						Using time effectively is key to reaching goals. If you're the type that
						likes to follow battle-tested strategies, then there is some good news. You
						can plan and use time effectively using several available time management strategies.
					</ArticleCopy>
					<ArticleCopy>
						Even though the thought may cross your mind that time management is limited
						to business, it isn't. It's important to include your personal life into
						this balance as well. We don't all have the same hurdles, and we've all got
						different cultures, so find methods that work for you. Often times in
						America, we try to reduce the amount of social time during work hours.
						Southern European countries have a culture where social interaction is rated
						as highly important. So there is no obvious line on what a priority should
						be.
					</ArticleCopy>
					<ArticleCopy>
						Are you trying to get organized, use your time without interruption, work
						towards a prize, or correct some bad habits? There are various techniques to
						choose from.
					</ArticleCopy>
					<ArticleCopy>
						Let's take a look at five strategies. Some of these can be combined to be
						most effective.
					</ArticleCopy>

					<ArticleList gutters>

						<ArticleListItem title="ABCD Analysis" component="div">
							<p>
								The ABCD Analysis involves categorizing tasks into four different
								groups. The criteria is:
							</p>
							<ArticleQuote
								cite={(<footer>&mdash;Wikipedia contributors. <AnchorLink href="https://en.wikipedia.org/wiki/Time_management#ABCD_analysis"><cite>"Time management."</cite></AnchorLink> Wikipedia, The Free Encyclopedia. Wikipedia, The Free Encyclopedia, 4 Mar. 2020. Web. 5 Mar. 2020.</footer>)}
							>
								<ul>
									<li>A &ndash; Tasks that are perceived as being urgent and important,</li>
									<li>B &ndash; Tasks that are important but not urgent,</li>
									<li>C &ndash; Tasks that are unimportant but urgent,</li>
									<li>D &ndash; Tasks that are unimportant and not urgent.</li>
								</ul>
							</ArticleQuote>
							<p>
								Within these groups, you can rank tasks in order based on priority.
								Ideally, you'll only be working on groups ABC. To further reduce the
								number of groups, you may attempt to move group B tasks to A or C.
								At this point, you can clearly work on group A, then group C.
							</p>
							<ArticleImg
								alt="ABCD"
								caption={<UnsplashPhotoCredit author="Diomari Madulara" href="https://unsplash.com/@diomari"/>}
								imgData={data.abcdImg.childImageSharp.gatsbyImageData}
							/>
						</ArticleListItem>

						<ArticleListItem title="Pareto Analysis" component="div">
							<p>
								The
								{' '}
								<AnchorLink href="https://en.wikipedia.org/wiki/Pareto_analysis">Pareto Analysis</AnchorLink>
								{' '}
								is a "low hanging fruit" strategy. The idea is that you can get 80%
								of your tasks done in 20% of the time. And the other 20% of tasks
								will take 80% of your time. To be most productive, prioritize the
								former tasks.
							</p>
							<p>
								Often times the landscape changes, and your to-do list gets new
								priorities. Once you've done the first 80%, you can reevaluate your
								current task list. Depending on the type of task, the 80% may have
								solved enough of your problem. The original, slower 20% might not
								even be a priority anymore.
							</p>
							<ArticleImg
								alt="Low Hanging Fruit"
								caption={<UnsplashPhotoCredit author="Marina Khrapova" href="https://unsplash.com/@mimiori"/>}
								imgData={data.lowHangingFruitImg.childImageSharp.gatsbyImageData}
							/>
						</ArticleListItem>

						<ArticleListItem title="The Eisenhower Method" component="div">
							<p>
								The Eisenhower Method (or <AnchorLink href="https://getpocket.com/explore/item/how-to-be-more-productive-and-eliminate-time-wasting-activities-by-using-the-eisenhower-box">Eisenhower Box</AnchorLink>)
								also involves grouping tasks based on importance and urgency.
								Dwight Eisenhower once quoted his "former college president":
							</p>
							<ArticleQuote
								cite={(<footer>&mdash;Dwight Eisenhower quoting a former college president as quoted by Wikipedia contributors. <AnchorLink href="https://en.wikipedia.org/wiki/Time_management#The_Eisenhower_Method"><cite>"Time management."</cite></AnchorLink> Wikipedia, The Free Encyclopedia. Wikipedia, The Free Encyclopedia, 4 Mar. 2020. Web. 5 Mar. 2020.</footer>)}
							>
								I have two kinds of problems, the urgent and the important. The
								urgent are not important, and the important are never urgent.
							</ArticleQuote>
							<p>
								This quote implies that importance and urgency are mutually
								exclusive. Although the strategy is inspired from the quote, it is
								actually a matrix of the two:
							</p>
							{/*<EisenhowerBox/>*/}
							<ArticleImg
								alt="Eisenhower Box"
								imgData={data.eisenhowerBoxImg.childImageSharp.gatsbyImageData}
							/>
							<p>
								Write your day's priorities in the matrix. Anything that's
								important, you're going to work on yourself. If it's urgent, but not
								important, delegate it. Then try to eliminate the remaining tasks.
								These non-urgent, non-important tasks might include items such as
								watching TV or checking social media.
							</p>
						</ArticleListItem>

						<ArticleListItem title="POSEC Method" component="div">
							<p>
								The POSEC Method is short for "Prioritize by Organizing,
								Streamlining, Economizing and Contributing". First, evaluate your
								own immediate and personal
								{' '}
								<AnchorLink href="https://en.wikipedia.org/wiki/Maslow%27s_hierarchy_of_needs">hierarchy of needs</AnchorLink>.
								Once you've done that, you'll be better suited to collaborate on
								joint efforts.
							</p>
							<p>
								Expanding upon the acronym:
							</p>
							<ArticleQuote
								cite={(<footer>&mdash;Wikipedia contributors. <AnchorLink href="https://en.wikipedia.org/wiki/Time_management#POSEC_method"><cite>"Time management."</cite></AnchorLink> Wikipedia, The Free Encyclopedia. Wikipedia, The Free Encyclopedia, 4 Mar. 2020. Web. 5 Mar. 2020.</footer>)}
							>
								<ol>
									<li><strong>Prioritize</strong> &ndash; Your time and define your life by goals.</li>
									<li><strong>Organize</strong> &ndash; Things you have to accomplish regularly to be successful (family and finances).</li>
									<li><strong>Streamline</strong> &ndash; Things you may not like to do, but must do (work and chores).</li>
									<li><strong>Economize</strong> &ndash; Things you should do or may even like to do, but they're not pressingly urgent (pastimes and socializing).</li>
									<li><strong>Contribute</strong> &ndash; By paying attention to the few remaining things that make a difference (social obligations).</li>
								</ol>
							</ArticleQuote>
							<p>
								Overall, the goal is to increase your personal productivity. Break
								large tasks into smaller ones, and you'll have an easier time
								keeping momentum. In this way, you'll be regularly reaching goals.
								Beyond that, this strategy encourages personal growth first.
							</p>
							<ArticleImg
								alt="POSEC method"
								imgData={data.posecMethodImg.childImageSharp.gatsbyImageData}
							/>
						</ArticleListItem>

						<ArticleListItem title="Pomodoro Technique" component="div">
							<p>
								The
								{' '}
								<AnchorLink href="https://en.wikipedia.org/wiki/Pomodoro_Technique">Pomodoro Technique</AnchorLink>
								{' '}
								is based around a tomato timer you'd find in the kitchen. By working
								on tasks in 25 minute intervals, you'll be able to easily evaluate
								progress. The goal is to remain focused on individual tasks,
								eliminating interruptions.
							</p>
							<p>
								These 25 minute intervals should not be interrupted. But if they are,
								record the task as either complete or postponed. After each pomodoro
								interval, mark the interval complete and take a 5 minute break.
								After four pomodoros, take a longer 15 minute break, and start over.
								You can use a
								{' '}
								<RouterLink to="/timesheet-app/">timesheet app</RouterLink>
								{' '}
								or an
								{' '}
								<AnchorLink href="https://tomato-timer.com/">online tomato timer</AnchorLink>
								{' '}
								instead of running out to the grocery store.
							</p>
							<p>
								When marking each interval complete, this gives you a sense of how
								much effort was put into a task. Additionally, it provides a sense
								of accomplishment. And when you know how much effort was spent on
								each task, you can improve future productivity.
							</p>

							<ArticleImg
								alt="Kitchen Timer"
								caption={<UnsplashPhotoCredit author="Marcelo Leal" href="https://unsplash.com/@marceloleal80"/>}
								imgData={data.timerImg.childImageSharp.gatsbyImageData}
							/>
						</ArticleListItem>

					</ArticleList>

					<ArticleCopy>
						Using various time management strategies, you'll be able to improve your
						productivity. Don't limit these techniques to just one. They often work well
						together. For example, the ABCD Analysis is regularly paired with the Pareto
						Analysis. Now you're efficiently reaching your goals with a greater sense of
						accomplishment!
						View our resource, <RouterLink to="/time-management-101/">Time Management 101</RouterLink>, to learn even more about boosting your productivity.
					</ArticleCopy>
				</ArticleBody>
			</ArticlePage>
		</AppLayout>
	);
}
/*
const useEisenhowerBoxStyles = makeStyles(theme => ({
	mainPaper: {
		display: "inline-block",
		padding: "2rem",
	},
	table: {
		borderCollapse: "collapse",
		"& tbody td": {
			verticalAlign: "top",
			width: "170px",
			borderWidth: "4px",
			borderStyle: "solid",
			borderColor: theme.palette.grey[100],
			"& ul": {
				paddingLeft: "1.3rem",
			},
		},
		"& th, td": {
			padding: "0 0.5rem",
		},
		"& > tbody > tr": {
			"& th": {
				height: "100px",
			},
		},
	},
	important: {
		"& td": {
			color: "#111",
			backgroundColor: "#a5ffd9",
		},
	},
	notImportant: {
		"& td": {
			color: "#111",
			backgroundColor: "#ffc37e",
		},
	},
}), {name: "EisenhowerBox"});

function EisenhowerBox(props) {
	const classes = useEisenhowerBoxStyles();
	return (
		<Paper className={classes.mainPaper}>
			<table className={classes.table}>
				<thead>
					<tr>
						<td/>
						<th>Urgent</th>
						<th>Not Urgent</th>
					</tr>
				</thead>
				<tbody>
					<tr className={classes.important}>
						<th>Important</th>
						<td>
							<ul>
								<li>Pick the kids up from school</li>
								<li>Pay invoices</li>
							</ul>
						</td>
						<td>
							<ul>
								<li>Add new software feature</li>
								<li>Go to the gym</li>
							</ul>
						</td>
					</tr>
					<tr className={classes.notImportant}>
						<th>Not Important</th>
						<td>
							<ul>
								<li>Collect survey results</li>
							</ul>
						</td>
						<td>
							<ul>
								<li>Watch TV</li>
								<li>Check Social Media</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</Paper>
	);
}
*/
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/time-management-strategies/o12-nAjil1z3eLk-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  abcdImg: file(relativePath: {eq: "images/articles/time-management-strategies/diomari-madulara-FFZjSpUwc_I-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 387, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  lowHangingFruitImg: file(relativePath: {eq: "images/articles/time-management-strategies/marina-khrapova-GbY8Xg5iTOA-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  eisenhowerBoxImg: file(relativePath: {eq: "images/articles/time-management-strategies/eisenhower-box.png"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  posecMethodImg: file(relativePath: {eq: "images/articles/time-management-strategies/posec-method.png"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timerImg: file(relativePath: {eq: "images/articles/time-management-strategies/marcelo-leal-vZawEq0Eexo-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 390, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
